@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');

body {
  font-family: 'Inter', sans-serif;
}

@keyframes slideInLeft {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.animate-slideInLeft {
  animation: slideInLeft 0.5s forwards;
  opacity: 0;
  /* Ensure the element is hidden before animation */
}



/* Webkit browsers */
::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 10px;
}

/* Firefox */
* {
  scrollbar-width: hidden;
  scrollbar-color: transparent;
}

.doublearrow-bg {
  background-image: url('./assets/icon/doublearrow.png');
}

.doublearrowend {
  background-image: url('./assets/icon/doublearrowend.svg');
  background-position: right;
  background-position-x: 90%;
}

.bg-img {
  background-image: url('./assets/icon/ourMission.png');
}

.nav-ul li {
  cursor: pointer;
}

.nav-ul li:hover {
  text-decoration: underline;
}

.registration-btn {
  background-color: #81BC06;
  width: 450px;
  height: 50px;
  border: none;
  border-radius: 10px;
  font-size: 20px;
  cursor: pointer;
  margin-top: 25px;
}

.headerNavbar li {
  margin: 25px;
  font-weight: bold;
  cursor: pointer;
  height: 25px;
}

.headerNavbar li:hover {
  border-bottom: 1px solid black;
}

.exploring-item {
  width: 255px;
  height: 255px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  background-color: white;
}

.exploring-item:hover {
  background-color: #81BC06;

  button {
    display: block;
    margin: auto;
    cursor: pointer;
  }
}

.exploring-item img {
  margin: auto;
}

.exploring-heading {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 400;
  line-height: 28.8px;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
}

.exploring-detail {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: center;
}

.exploring-item button {
  width: 110px;
  height: 35px;
  background-color: #81BC06;
  border-radius: 10px;
  display: none;
}

.contact_form {
  background-color: #F8F8F8;
  width: 500px;
  padding: 35px;
  box-sizing: border-box;
  /* Include padding in the width calculation */
  border: 1px solid #81BC06;
  border-radius: 10px;
}


.contact_form input,
.career_form input,
.career_form select,
textarea {
  width: 430px;
  height: 25px;
  border-radius: 10px;
  margin-bottom: 15px;
  margin-top: 5px;
  border: 1px solid gray;
}

.contact_form button {
  background-color: #81BC06;
  width: 400px;
  height: 50px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
}

.career_form_container {
  background-color: #81BC06;
}

.career_form {
  background-color: white;
  padding: 50px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

.career_form_fields {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.blog_buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
}

.blog_buttons button {
  background-color: white;
  width: Hug (266px)px;
  height: Hug (39px)px;
  padding: 10px 20px 10px 20px;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid #878787;
  opacity: 0px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: center;
  cursor: pointer;
}

.blog_buttons button:hover {
  background-color: #81BC06;
}

.file_input {
  margin: auto;
}

th {
  background-color: #81BC06;
  height: 90px;
  width: 100vw;

}

td {
  width: 150px;
}

.product-container {
  border: 2px solid #81BC06;
  border-radius: 10px;
  width: 1037px;
  padding: 50px;
  background-color: #F8F8F8;
  margin-top: 50px;
}

.blog-container {
  border: 2px solid #81BC06;
  border-radius: 10px;
  width: 1037px;
  background-color: #F8F8F8;
  margin-top: 50px;
}

.clientInput-container {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  background-color: white;
  width: 501px;
  height: 454px;
  border-radius: 10px;
  border: 2px solid #81BC06;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  flex-direction: column;
}

.logo-container {
  display: flex;
  flex-wrap: wrap;
}

.logo-container img {
  width: 231.05px;
  height: 157.27px;
  gap: 0px;
  border-radius: 10px 0px 0px 0px;
  opacity: 0px;
}

.marquee {
  display: flex;
  overflow-x: hidden;
  white-space: nowrap;
  will-change: transform;
}

.marquee-inner {
  display: flex;
  will-change: transform;
  gap: 50px;
  animation: marquee 10s linear infinite;
}

.marquee1 .marquee-inner {
  animation-direction: normal;
}

.marquee2 .marquee-inner {
  animation-direction: reverse;
}

@keyframes marquee {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(-50%);
  }
}

@media screen and (max-width: 5000px) and (min-width: 600px) {
  .marquee {
    max-width: 98vw;
  }
}

.custom-crop {
  width: 400px;
  /* Adjust as needed */
  height: 400px;
  /* Adjust as needed */
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-image: url(data:image/svg+xml;base64,PCEtLT94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/LS0+CiAgICAgICAgICAgICAgPHN2ZyBpZD0ic3ctanMtYmxvYi1zdmciIHZpZXdCb3g9IjAgMCAxMDAgMTAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSI+CiAgICAgICAgICAgICAgICAgICAgPGRlZnM+IAogICAgICAgICAgICAgICAgICAgICAgICA8bGluZWFyR3JhZGllbnQgaWQ9InN3LWdyYWRpZW50IiB4MT0iMCIgeDI9IjEiIHkxPSIxIiB5Mj0iMCI+CiAgICAgICAgICAgICAgICAgICAgICAgICAgICA8c3RvcCBpZD0ic3RvcDEiIHN0b3AtY29sb3I9InJnYmEoMjQ4LCAxMTcsIDU1LCAxKSIgb2Zmc2V0PSIwJSI+PC9zdG9wPgogICAgICAgICAgICAgICAgICAgICAgICAgICAgPHN0b3AgaWQ9InN0b3AyIiBzdG9wLWNvbG9yPSJyZ2JhKDI1MSwgMTY4LCAzMSwgMSkiIG9mZnNldD0iMTAwJSI+PC9zdG9wPgogICAgICAgICAgICAgICAgICAgICAgICA8L2xpbmVhckdyYWRpZW50PgogICAgICAgICAgICAgICAgICAgIDwvZGVmcz4KICAgICAgICAgICAgICAgIDxwYXRoIGZpbGw9InVybCgjc3ctZ3JhZGllbnQpIiBkPSJNMjYsLTMzLjVDMzEuMywtMjYuNCwzMS44LC0xNi4xLDMyLjQsLTYuNkMzMywyLjksMzMuNywxMS40LDMwLjQsMTcuOUMyNy4xLDI0LjUsMTkuNywyOS4xLDExLjgsMzEuNkMzLjksMzQuMiwtNC41LDM0LjgsLTEyLjMsMzIuNUMtMjAsMzAuMiwtMjcuMiwyNSwtMzIuNCwxNy44Qy0zNy43LDEwLjYsLTQxLDEuNCwtMzkuNiwtNy4xQy0zOC4yLC0xNS42LC0zMi4xLC0yMy4zLC0yNC43LC0zMEMtMTcuNCwtMzYuNywtOC43LC00Mi40LDAuOCwtNDMuNEMxMC4zLC00NC40LDIwLjYsLTQwLjYsMjYsLTMzLjVaIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg1MCA1MCkiIHN0cm9rZS13aWR0aD0iMCIgc3R5bGU9InRyYW5zaXRpb246IGFsbCAwLjNzIGVhc2UgMHM7Ij48L3BhdGg+CiAgICAgICAgICAgICAgPC9zdmc+);
  /* For Safari */
  overflow: hidden;
  object-fit: cover;
  /* Ensures the image covers the entire area */
}

/* Add this CSS to your stylesheet */

@keyframes slideIn {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes slideOut {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

.slide-right {
  animation: slideIn 1s forwards;
}

.slide-left {
  animation: slideOut 1s forwards;
}

.slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 786px;
  opacity: 0;
  transition: opacity 1s;
}

.slide-active {
  opacity: 1;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.animate-fadeIn {
  animation: fadeIn 1s forwards;
}